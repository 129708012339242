import React from 'react';
import MCSERVERS from '../../assets/images/mc-servers.PNG';
import MinecraftPE from '../../assets/images/minecraftpe-servers.png';
import IOWA_MAP from '../../assets/images/iowa-map.png';
import ARKITEMIDS from '../../assets/images/arkitemids.png';
import BESTSERVERS from '../../assets/images/bestservers.PNG';
import MINECRAFTITEMIDS from '../../assets/images/minecraftitemids.PNG';
import TERRARIAITEMIDS from '../../assets/images/terrariaitemids.PNG';
import MINESTATUS from '../../assets/images/minestatus.PNG';
import GAMEITEMS from '../../assets/images/gameitems.PNG';

import './style.css';

const ProfilePage = (props, context) => {

    return (
        <div className="portfolio-page">
            <main className="portfolio-container">
                <div className="portfolio-wrapper">
                    <div className="portfolio-title">Portfolio</div>
                    <div className="portfolio-content">
                        <p className="portfolio-text">Below are some of my projects I currently own and maintain!</p><br />
                        <div className="project-box">
                            <div className="box-title"><a href="https://mc-servers.com">MC-SERVERS.com</a></div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-1" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">MC-SERVERS is a Minecraft Server List that is built using primarly PHP. I started
                                    <span className="read-more-target"> this website in 2014 and has been very successful averaging about 80k unique visitors a month and has over 5.5 Million pageviews!</span></p>
                                </ul>

                                <label htmlFor="post-1" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={MCSERVERS} width="100%" height="250px" alt="MC-SERVERS" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fab fa-php fa-3x"></i>
                            </div>
                        </div>
                        <div className="project-box">
                            <div className="box-title"><a href="https://minestatus.net">Minestatus.net</a></div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-2" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">Minestatus is a Minecraft Server List that is built using Node.js, EJS and MongoDB. I
                                    <span className="read-more-target"> bought out this competitor and so far have seen much success!</span></p>
                                </ul>

                                <label htmlFor="post-2" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={MINESTATUS} width="100%" height="250px" alt="MINESTATUS" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fab fa-node fa-3x"></i>
                            </div>
                        </div>
                        <div className="project-box">
                            <div className="box-title"><a href="https://bestservers.com">BestServers.com</a></div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-3" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">BestServers is a gaming top server list that was first released at the beginning of
                                    <span className="read-more-target"> 2020. The site is like MC-SERVERS except that it is made to support many games such as Minecraft, Ark, Unturned, Rust, Runescape, World of Warcraft, etc. This is a much larger script and I hope one day becomes popular! </span></p>
                                </ul>

                                <label htmlFor="post-3" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={BESTSERVERS} width="100%" height="250px" alt="BESTSERVERS" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fab fa-php fa-3x"></i>
                            </div>
                        </div>
                        <div className="project-box">
                        <div className="box-title"><a href="https://gameitems.org">GameItems.org</a></div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-4" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">GameItems is a game item list that is built using Node.js, EJS and MongoDB. This
                                    <span className="read-more-target"> site is an all-in-one hub for finding game item ids for the hottest games such as Minecraft, Ark, Unturned and more! I plan on expanding and adding many more games in the near future.</span></p>
                                </ul>

                                <label htmlFor="post-4" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={GAMEITEMS} width="100%" height="250px" alt="GameItems" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fab fa-node fa-3x"></i>
                            </div>
                        </div>
                        <div className="project-box">
                        <div className="box-title"><a href="https://arkitemids.org">ArkItemIDs.org</a></div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-5" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">ArkItemIDs is a website built with ReactJS and uses a react framework called
                                    <span className="read-more-target"> NextJS which does SSR. For the database side I am using Postgres and GraphQL. The point of this website is to list game item ids for the game Ark: Survival Evolved.</span></p>
                                </ul>

                                <label htmlFor="post-5" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={ARKITEMIDS} width="100%" height="250px" alt="ArkItemIDs" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fab fa-react fa-3x"></i>
                            </div>
                        </div>
                        <div className="project-box">
                        <div className="box-title"><a href="https://minecraftitemids.org">MinecraftItemIDs.org</a></div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-6" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">MinecraftItemIDs is a website built with ReactJS and uses a react framework called
                                    <span className="read-more-target"> NextJS which does SSR. For the database side I am using Postgres and GraphQL. The point of this website is to list game item ids for the game Minecraft.</span></p>
                                </ul>

                                <label htmlFor="post-6" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={MINECRAFTITEMIDS} width="100%" height="250px" alt="MinecraftItemIDs" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fab fa-react fa-3x"></i>
                            </div>
                        </div>
                        <div className="project-box">
                        <div className="box-title"><a href="https://terrariaitemids.com">TerrariaItemIDs.com</a></div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-7" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">TerrariaItemIDs is a website built with ReactJS and uses a react framework called
                                    <span className="read-more-target"> NextJS which does SSR. For the database side I am using Postgres and GraphQL. The point of this website is to list game item ids for the game Terraria.</span></p>
                                </ul>

                                <label htmlFor="post-7" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={TERRARIAITEMIDS} width="100%" height="250px" alt="TERRARIAITEMIDs" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fab fa-react fa-3x"></i>
                            </div>
                        </div>
                        <div className="project-box">
                            <div className="box-title"><a href="https://minecraftpe-servers.com" >MinecraftPE-Servers.com</a></div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-8" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">MinecraftPE-Servers is a Minecraft PE Server List that is very similar to
                                    <span className="read-more-target"> MC-SERVERS except that it is a top list for the pocket edition of Minecraft. </span></p>
                                </ul>

                                <label htmlFor="post-8" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={MinecraftPE} width="100%" height="250px" alt="Minecraft PE Servers" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fab fa-php fa-3x"></i>
                            </div>
                        </div>
                        <div className="project-box">
                            <div className="box-title">Interactive Map Of Iowa</div>
                            <div className="read-more-div">
                                <input type="checkbox" className="read-more-state" id="post-9" />

                                <ul className="read-more-wrap">
                                    <p className="read-more-p">This interactive map of Iowa that I created while in college. The map was built for a 
                                    <span className="read-more-target"> non-profit organization and uses HTML, CSS, JavaScript, JQuery, JSON, and Leaflet. <a style={{color: 'blue'}} href="https://minecraftpe-servers.com/mis447">Click here for demo!</a></span></p>
                                </ul>

                                <label htmlFor="post-9" className="read-more-trigger"></label>
                            </div>
                            <div className="box-image"><img className="box-image-style" src={IOWA_MAP} width="100%" height="250px" alt="Iowa Map" /></div>
                            <div className="tech-icons">
                            <i className="fab fa-html5 fa-3x"></i>
                            <i className="fab fa-css3-alt fa-3x"></i>
                            <i className="fab fa-js fa-3x"></i>
                            <i className="fas fa-leaf fa-3x"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ProfilePage;