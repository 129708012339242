import React from 'react';
import Austin from '../../assets/images/austin-hi.png';
import './style.css';

const LandingPage = (props, context) => {
    
    return (
        <>
            <div className="home-page"></div>
            <img className="bitmoji" src={Austin} width="300rem" alt="Austin Bitmoji" />
        <div className="landing-page">
        <main className="landing-container">
            <div className="intro-wrapper">
                <div className="intro-name">Hello, I'm Austin!</div>
                <div className="tagline">
    
                Software Engineer | Designer | Web Dev Enthusiast
                </div>
                <div className="tagline-mobile">
                <p className="tag">Software Engineer</p>
                <p className="tag">Designer</p>
                <p className="tag">Web Dev Enthusiast</p>
                </div>
                <div className="contact-icons">
            <a className="icon" href="https://www.linkedin.com/in/austin-plogman-2b9995107/"><i className="fab fa-linkedin fa-3x icon-margin"></i></a>
            <a className="icon" href="https://www.facebook.com/austin.plogman"><i className="fab fa-facebook fa-3x icon-margin"></i></a>
            <a className="icon" href="mailto:akplogman@gmail.com"><i className="fas fa-envelope fa-3x icon-margin"></i></a>
            </div>
            </div>
        </main>
        </div>
        </>
    )
}

export default LandingPage;