import React from 'react';

import './style.css';

const Footer = (props, context) => {
    return (
        <footer>
            <div className="content-grid">
                <div className="text-color">© Copyright 2021 Austin Plogman</div>
            </div>
        </footer>
    )
}

export default Footer;