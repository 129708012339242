import React, { Component } from 'react';
import { toElement as scrollToElement } from '../../utils/scroll';
import reactIcon from '../../assets/images/reactjsicon.png';
import './style.css';


class Nav extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      isSticky: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > this.nav.offsetTop) {
      this.setState({
        isSticky: true
      });
    } else {
      this.setState({
        isSticky: false
      });
    }
  }

  scrollToPage(pageSelector) {
    const nextPage = document.querySelector(pageSelector);
    scrollToElement(nextPage);
  }

render() {
    const stickyClass = this.state.isSticky ? 'sticky' : '';
    
    return (
        <>
            <div className="built-with-wrapper">
        <div className="built-with">
            Built With ReactJS <img className="react-icon" src={reactIcon} width="16" height="16" alt="reactIcon"/>
        </div>
        </div>

        <nav
            className={stickyClass}
            ref={(elem) => {
                this.nav = elem;
            }}
        >
        <div
            className="menu__item from-top hover-underline"
            onClick={(e) => this.scrollToPage('.home-page')}
            >
            Contact
        </div>
        <div
            className="menu__item active from-top hover-underline"
            onClick={(e) => this.scrollToPage('.about-page')}
            >
            About
        </div>
        <div
            className="menu__item from-top hober-underline"
            onClick={(e) => this.scrollToPage('.portfolio-page')}
            >
            Portfolio
        </div>
    </nav>
    </>
    );
    }

}


export default Nav;