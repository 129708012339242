import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import LandingPage from '../../pages/LandingPage/LandingPage';
import AboutPage from '../../pages/AboutPage/AboutPage';
import PortfolioPage from '../../pages/PortfolioPage/PortfolioPage';
import Nav from '../../components/Nav/Nav';

import '../../styles/background.css';



class Layout extends Component {

    render() {
        return (
            <>
                <Nav />
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
                <LandingPage />
                <AboutPage />
                <PortfolioPage />
                <Footer />
            </>
        )
    }
}

export default Layout;
