import React from 'react';

import './style.css';

const AboutPage = (props, context) => {
    
    return (
        <div className="about-page">
        <main className="about-container">
        <div className="about-wrapper">
            <div className="about-title">About</div>
            <div className="about-content">
                <p className="about-text">Welcome to my personal website! Over the years I have gained a great amount of interest in website development and programming languages. In 2018 I graduated from Iowa State University with a Bachelor of Science in MIS and I am now working as a Software Engineer in Iowa. 
                    My passion with computers started before high school and ever since I've been striving to learn and strengthen my skills!</p><br />
                <p className="about-text">My top skills are included below:</p>
                <div className="skills-content">
                <i className="fab fa-html5 fa-5x"></i>
                <i className="fab fa-css3-alt fa-5x"></i>
                <i className="fab fa-react fa-5x"></i>
                <i className="fab fa-php fa-5x tech-icon"></i>
                <i className="fab fa-js fa-5x tech-icon"></i>
                <i className="fab fa-node fa-5x tech-icon"></i>
                </div>
            </div>
            </div>
        </main>
        </div>
    )
}

export default AboutPage;